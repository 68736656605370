<template>
    <div>
        <v-card v-if="orgTablesStatus === true" flat>
            <v-card-title>
                <div style="width:100%" class="d-flex flex-column">
                    <div class="d-flex justify-space-between align-center">
                        <div class="d-flex flex-column">
                            <div class="d-flex align-center">
                                <span class="primary--text ma-0 font-weight-bold">Tables</span>
                                <v-tooltip v-if="tablesStatus === tableStatusOptions.ACTIVATED_TABLES" bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            @click="$store.dispatch('snapshotStore/fetchCurrentTables', $route.params.snid)"
                                            icon
                                            v-on="on"
                                            :loading="tablesFetching"
                                            ><v-icon color="primary">refresh</v-icon></v-btn
                                        >
                                    </template>
                                    <span>Refresh Tables</span>
                                </v-tooltip>
                            </div>
                            <span v-if="tablesFetching === true && tablesStatus === tableStatusOptions.ACTIVATED_TABLES" class="caption text-uppercase ma-0">
                                Fetching tables....
                            </span>
                            <span v-else-if="tablesStatus === tableStatusOptions.ACTIVATED_TABLES" class="caption text-uppercase ma-0">
                                {{ currentTables.length }} tables found
                            </span>
                            <span v-else-if="tablesStatus === null" class="caption text-uppercase ma-0">Checking tables status...</span>
                        </div>
                        <div v-if="tablesStatus === tableStatusOptions.ACTIVATED_TABLES && tablesFetching !== true" class="d-flex align-center">
                            <div class="d-flex align-center">
                                <div v-if="currentTables.length > 0" class="d-flex align-center mr-2">
                                    <v-switch hide-details class="ma-0 pa-0" v-model="showSqlEditor"> </v-switch>
                                    <span class="text-uppercase subtitle-2 font-weight-bold primary--text">sql editor</span>
                                </div>
                                <v-menu v-model="connectionMenu" :close-on-content-click="false" :nudge-width="100" :max-width="650" offset-y left>
                                    <template v-slot:activator="{ on: menu }">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on: tooltip }">
                                                <v-btn text color="primary" dark v-on="{ ...tooltip, ...menu }">
                                                    <div class="d-flex align-center">
                                                        <v-icon small>mdi-book-open-variant</v-icon
                                                        ><span class="subtitle-2 font-weight-bold ml-1">connection guide</span>
                                                    </div>
                                                </v-btn>
                                            </template>
                                            <span>Connection guide</span>
                                        </v-tooltip>
                                    </template>
                                    <v-card style="overflow-y:hidden">
                                        <v-card-title>
                                            <span class="primary--text font-weight-bold">Connection guide for external applications</span></v-card-title
                                        >
                                        <v-card-text v-if="currentSpaceType !== spaceTypes.VENDOR_SPACE">
                                            <div class="d-flex flex-column">
                                                <span class="subtitle-2 primary--text">
                                                    If you want to connect to the database with a 3rd party application, please consult Snowflake's
                                                    documentation
                                                    <a
                                                        href="https://docs.nuvolos.cloud/data/access-data-from-applications#accessing-data-tables-from-external-non-nuvolos-applications"
                                                        >here</a
                                                    >.
                                                </span>
                                                <span class="subtitle-2 primary--text">In any case, you'll need the following credentials to connect:</span>
                                                <v-row v-for="(item, index) in connectionGuideData" :key="index" align="center" no-gutters>
                                                    <v-col class="font-weight-bold text-uppercase secondary--text" cols="3">{{ item.item }}</v-col>
                                                    <v-col class="subtitle-2" cols="9">
                                                        <div class="d-flex align-center">
                                                            {{ item.data }}
                                                            <CopyToClipboard
                                                                :textToCopy="item.data"
                                                                buttonClass="caption font-weight-bold"
                                                                buttonColor="secondary"
                                                                :isTextButton="true"
                                                                buttonName="copy to clipboard"
                                                                :iconButton="true"
                                                            ></CopyToClipboard>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                                <span class="subtitle-2 primary--text">
                                                    Once connected, execute the following SQL command to activate the schema corresponding to the currently
                                                    selected state:
                                                </span>
                                                <div
                                                    class="d-flex justify-space-between align-center wrap-flex"
                                                    style="background-color: rgba(23, 168, 193, 0.1); width:100%"
                                                >
                                                    <span class="mb-0 mt-0 ml-2 font-weight-bold caption" style="color:rgba(0, 0, 0, 0.54)">SQL Editor</span>
                                                    <CopyToClipboard
                                                        :textToCopy="connectionQuery"
                                                        buttonClass="caption font-weight-bold"
                                                        buttonColor="secondary"
                                                        :isTextButton="true"
                                                        buttonName="copy SQL command to clipboard"
                                                    ></CopyToClipboard>
                                                </div>
                                                <MonacoEditor
                                                    class="monaco-editor mb-3"
                                                    :style="{ height: '32px' }"
                                                    v-model="connectionQuery"
                                                    language="sql"
                                                    :options="{
                                                        minimap: { enabled: false },
                                                        fontSize: 14,
                                                        renderLineHighlight: false,
                                                        readOnly: true,
                                                        automaticLayout: true,
                                                        autoIndent: true,
                                                        lineNumbersMinChars: 0,
                                                        scrollbar: { vertical: 'hidden' },
                                                        scrollBeyondLastLine: false,
                                                        smoothScrolling: true
                                                    }"
                                                />
                                            </div>
                                        </v-card-text>
                                        <v-card-text v-else-if="currentSpaceType === spaceTypes.VENDOR_SPACE">
                                            <v-alert type="warning" prominent text>
                                                You cannot access vendor datasets directly from 3rd party applications. To access data with an external
                                                application, stage the tables you want to work with and distribute them to a course/research space.
                                            </v-alert>
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                                <div class="d-flex justify-end">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                text
                                                color="primary"
                                                v-on="on"
                                                href="https://docs.nuvolos.cloud/data/access-data-from-applications/upload-data-to-nuvolos"
                                                target="_blank"
                                            >
                                                <div class="d-flex align-center">
                                                    <v-icon small>menu_book</v-icon>
                                                    <span class="subtitle-2 font-weight-bold ml-1">import data</span>
                                                </div>
                                            </v-btn>
                                        </template>
                                        <span>Read the documentation on how to import and upload data to Nuvolos</span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card-title>
            <v-card-text class="mt-3">
                <v-card v-if="tablesStatus === tableStatusOptions.ACTIVATED_TABLES && tablesFetching !== true && currentTables.length" flat>
                    <v-card-text class="pa-0">
                        <div v-if="showSqlEditor">
                            <div class="d-flex align-center" style="background-color: rgba(23, 168, 193, 0.1); width:100%">
                                <span class="my-0 ml-2 font-weight-bold caption" style="color:rgba(0, 0, 0, 0.54)">SQL Editor</span>
                            </div>
                            <MonacoEditor
                                class="monaco-editor mb-3"
                                :style="{ height: queryLines }"
                                v-model="sqlQuery"
                                language="sql"
                                :options="{
                                    minimap: { enabled: false },
                                    fontSize: 14,
                                    renderLineHighlight: false,
                                    automaticLayout: true,
                                    autoIndent: true,
                                    lineNumbersMinChars: 3,
                                    scrollbar: { vertical: 'hidden' },
                                    scrollBeyondLastLine: false,
                                    smoothScrolling: true
                                }"
                            />
                            <QuerySubmit
                                mode="PREVIEW"
                                buttonName="preview"
                                tooltipHint="Returns a preview sample of the query outcome."
                                :iconShaped="false"
                                :consoleSql="sqlQuery"
                                @success="tabView = tabOptions.QUERY_HISTORY"
                            ></QuerySubmit>
                        </div>
                        <v-tabs height="30" v-model="tabView" color="secondary" class="mt-5 tabs-grey-border-bottom" show-arrows>
                            <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
                            <v-tab dark height="10">
                                <span class="font-weight-bold">tables ({{ currentTables.length }})</span>
                            </v-tab>
                            <v-tab-item class="mt-5">
                                <SnapshotTableList
                                    @switchTab="tabView = $event.tab"
                                    @newTableSelected="currentTableSelected = $event.table"
                                ></SnapshotTableList>
                            </v-tab-item>
                            <v-tab dark height="10" :disabled="!currentColumns.length">
                                <span class="font-weight-bold">columns ({{ currentColumns.length }})</span>
                            </v-tab>
                            <v-tab-item class="mt-3">
                                <SnapshotTableColumns :currentTableSelected="currentTableSelected" />
                            </v-tab-item>
                            <v-tab dark height="10" :disabled="!currentTableSelected.length">
                                <span class="font-weight-bold">table preview</span>
                            </v-tab>
                            <v-tab-item class="mt-3">
                                <SnapshotTablePreview :currentTableSelected="currentTableSelected" />
                            </v-tab-item>
                            <v-tab dark height="10" :disabled="disableQueryFiles">
                                <span class="font-weight-bold">Query Files</span>
                            </v-tab>
                            <v-tab-item class="mt-3">
                                <QueryFiles></QueryFiles>
                            </v-tab-item>
                            <v-tab dark height="10" :disabled="disableQueryPreview">
                                <span class="font-weight-bold">query preview</span>
                            </v-tab>
                            <v-tab-item class="mt-5">
                                <QueryTablePreview
                                    @querySubmitSuccess="tabView = tabOptions.QUERY_HISTORY"
                                    @queryFilesSaveSuccess="tabView = tabOptions.QUERY_HISTORY"
                                ></QueryTablePreview>
                            </v-tab-item>
                            <v-tab dark width="50">
                                <span class="font-weight-bold">query history</span>
                            </v-tab>
                            <v-tab-item class="mt-5">
                                <QueryHistory
                                    v-if="snapshotQueries.length"
                                    :snapshotQueries="snapshotQueries"
                                    @queryResultsSuccess="moveToQueryPreview()"
                                    @sqlText="pasteIntoConsole($event.value)"
                                    @queryFilesSuccess="moveToQueryFiles()"
                                ></QueryHistory>
                                <v-alert v-else prominent type="info" text>Query history is empty.</v-alert>
                            </v-tab-item>
                        </v-tabs>
                    </v-card-text>
                </v-card>
                <div v-else-if="tablesFetching === true" class="mt-5">
                    <div>
                        <v-skeleton-loader class="mx-auto" type="text"></v-skeleton-loader>
                    </div>
                    <div>
                        <v-skeleton-loader class="mx-auto" type="list-item-three-line" v-for="(n, i) in 3" :key="i"></v-skeleton-loader>
                    </div>
                </div>
                <v-banner v-if="tablesFetching === false && (tablesStatus === tableStatusOptions.ACTIVATING_TABLES || activatingTables)" single-line>
                    <v-avatar slot="icon" color="white" size="60">
                        <v-icon x-large icon="info" color="info">
                            info
                        </v-icon>
                    </v-avatar>
                    Tables are being activated, this can take a few minutes. You can periodically use the refresh button to see if activation has finished.
                    <template v-slot:actions>
                        <v-btn :loading="fetchingSpaces" small color="info" outlined @click="fetchTableStatus">refresh</v-btn>
                    </template>
                </v-banner>
                <v-banner v-if="tablesStatus === tableStatusOptions.INACTIVE_TABLES && activatingTables === false" single-line>
                    <v-avatar slot="icon" color="white" size="60">
                        <v-icon x-large icon="info" color="info">
                            info
                        </v-icon>
                    </v-avatar>
                    <div class="d-flex flex-column">
                        <span class="font-weight-bold">Tables not activated</span>
                        You haven't activated the tables features for this state yet.
                    </div>
                    <template v-slot:actions>
                        <v-btn small color="info" outlined @click="activateTable">activate tables</v-btn>
                    </template>
                </v-banner>
                <v-banner
                    v-if="
                        (activatingTables === false && tablesStatus === tableStatusOptions.EMPTY_TABLES) ||
                            (activatingTables === false &&
                                tablesStatus === tableStatusOptions.ACTIVATED_TABLES &&
                                tablesFetching === false &&
                                !currentTables.length)
                    "
                    single-line
                >
                    <v-avatar slot="icon" color="white" size="60">
                        <v-icon x-large icon="info" color="info">
                            info
                        </v-icon>
                    </v-avatar>
                    <div class="d-flex flex-column">
                        <span class="font-weight-bold">No tables found</span>
                        <span v-if="isDevelopment">
                            No tables were found. This might be because there are no tables in this space or tables have not been activated.
                        </span>
                        <span v-else>No tables were found in this snapshot. </span>
                    </div>
                </v-banner>
            </v-card-text>
        </v-card>
        <v-card v-else-if="orgTablesStatus === false" flat>
            <v-card-title>
                <span class="primary--text font-weight-bold">Tables</span>
            </v-card-title>
            <v-card-text>
                <v-alert type="info" prominent text>
                    <div class="d-flex flex-column">
                        <span class="font-weight-bold">Tables not activated</span>
                        The current subscription does not allow for the usage of Tables in Nuvolos. Please reach out to your organization administrator if you
                        require this feature.
                    </div>
                </v-alert>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import 'github-markdown-css/github-markdown.css'
import { enumsData } from '@/mixins/enums'
const QuerySubmit = () => import('../components/TheSnapshotSubmitQuery')
const QueryHistory = () => import('../components/TheSnapshotQueryHistoryTable')
const QueryTablePreview = () => import('../components/TheSnapshotQueryPreviewTable')
const QueryFiles = () => import('../components/TheSnapshotQueryFiles')
const MonacoEditor = () => import('vue-monaco')
const CopyToClipboard = () => import('@/components/CopyToClipboard')
const SnapshotTablePreview = () => import('../components/TheSnapshotTablePreview')
const SnapshotTableColumns = () => import('../components/TheSnapshotTableColumns')
const SnapshotTableList = () => import('../components/TheSnapshotTableListIterator')
export default {
    name: 'snapshot-tables',
    mixins: [enumsData],
    components: {
        QuerySubmit,
        QueryHistory,
        QueryTablePreview,
        MonacoEditor,
        QueryFiles,
        CopyToClipboard,
        SnapshotTablePreview,
        SnapshotTableColumns,
        SnapshotTableList
    },
    data() {
        return {
            tableSearch: '',
            showSqlEditor: false,
            tableHeaders: [
                { text: 'Name', align: 'left', value: 'short_id', width: '200px' },
                {
                    text: 'Description',
                    align: 'left',
                    value: 'description',
                    width: '400px'
                },
                { text: 'Row count', value: 'row_count', align: 'end', width: '10%' },
                { text: 'Size', align: 'end', value: 'bytes', width: '10%' },
                { text: 'Actions', align: 'center', value: 'actions', width: '200px' }
            ],
            hasTables: null,
            activatingMode: false,
            dialog: false,
            tabView: 0,
            sqlQuery: 'SELECT *\nFROM\nWHERE\nLIMIT 10',
            tabOptions: {
                TABLE_LIST: 0,
                COLUMN_LIST: 1,
                TABLE_PREVIEW: 2,
                QUERY_FILES: 3,
                QUERY_PREVIEW: 4,
                QUERY_HISTORY: 5
            },
            queryUpdater: null,
            queryTableColumns: [],
            disableQueryPreview: true,
            disableQueryFiles: true,
            tableActivation: null,
            sqlDialog: false,
            connectionMenu: false,
            currentTableSelected: '',
            tableStatusOptions: {
                ACTIVATED_TABLES: 'active',
                ACTIVATING_TABLES: 'activating',
                INACTIVE_TABLES: 'inactive',
                EMPTY_TABLES: 'empty'
            },
            activatingTables: false
        }
    },
    computed: {
        ...mapState('snapshotStore', [
            'currentTables',
            'currentColumns',
            'tablesFetching',
            'previewColumnHeaders',
            'columnsFetching',
            'stagingObjects',
            'snapshotQueries',
            'latestQuery',
            'fullQueryData',
            'tablesPath'
        ]),
        ...mapState('userStore', ['snowflakeTokens']),
        ...mapGetters('orgStore', ['orgTablesStatus']),
        ...mapGetters('spaceStore', ['currentSpaceType']),
        ...mapGetters('snapshotStore', ['isDevelopment']),
        ...mapState('orgStore', ['spacesWithPrimarySnapshots', 'fetchingSpaces']),
        tableNamesArray() {
            if (this.currentTables) {
                return this.currentTables.map(table => table.short_id)
            }
            return []
        },
        connectionGuideData() {
            const username = this.$store.state.userInfo.username
            const password = this.snowflakeTokens.access_token
            const schema = this.tablesPath.schema
            const database = this.tablesPath.db
            const hostname = 'alphacruncher.eu-central-1.snowflakecomputing.com'
            const fullData = [
                { item: 'username', data: username },
                { item: 'password', data: password },
                { item: 'schema', data: schema },
                { item: 'database', data: database },
                { item: 'hostname', data: hostname }
            ]
            return fullData
        },
        queryLines() {
            if (this.$data.sqlQuery.split('\n').length === 1) {
                return '32px'
            } else {
                return this.$data.sqlQuery.split('\n').length * 22 + 'px'
            }
        },
        spaceTableData() {
            if (this.orgTablesStatus === true && this.spacesWithPrimarySnapshots) {
                const currentSpaceData = this.spacesWithPrimarySnapshots.filter(space => space.sid.toString() === this.$route.params.sid.toString())
                if (currentSpaceData) {
                    return currentSpaceData[0]
                } else {
                    return null
                }
            }
            return null
        },
        connectionQuery() {
            const db = this.tablesPath.db
            const schema = this.tablesPath.schema
            return 'USE SCHEMA "' + db + '"' + '."' + schema + '"'
        },
        tablesStatus() {
            if (this.spaceTableData) {
                if (this.spaceTableData.space_lock_mode_name === 'ACTIVATING' && !this.tablesFetching) {
                    return this.$data.tableStatusOptions.ACTIVATING_TABLES
                } else if (this.spaceTableData.has_tables === null && this.isDevelopment === true && !this.tablesFetching) {
                    return this.$data.tableStatusOptions.INACTIVE_TABLES
                } else if (this.spaceTableData.has_tables === true) {
                    return this.$data.tableStatusOptions.ACTIVATED_TABLES
                } else if (
                    this.spaceTableData.has_tables === null &&
                    this.spaceTableData.space_lock_mode_name !== 'ACTIVATING' &&
                    !this.isDevelopment === true &&
                    !this.tablesFetching
                ) {
                    return this.$data.tableStatusOptions.EMPTY_TABLES
                } else {
                    return null
                }
            } else {
                return null
            }
        }
    },
    methods: {
        moveToQueryPreview: function() {
            this.$data.disableQueryPreview = false
            this.$data.tabView = this.$data.tabOptions.QUERY_PREVIEW
        },
        moveToQueryFiles: function() {
            this.$data.disableQueryFiles = false
            this.$data.tabView = this.$data.tabOptions.QUERY_FILES
        },
        pasteIntoConsole(sql) {
            this.$data.sqlQuery = sql
        },
        fetchTableStatus: function() {
            this.$store.dispatch('orgStore/fetchOrgSpaces', this.$route.params.oid)
        },
        activateTable() {
            this.$data.activatingTables = true
            this.$store.dispatch('showSnackBar', {
                snackBarText: 'Activating tables...',
                snackBarTimeout: 10000,
                snackBarIcon: 'info'
            })
            this.$axios.get(`/spaces/${this.$route.params.sid}/activate_async`).then(response => {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Table activation has started...',
                    snackBarTimeout: 10000,
                    snackBarIcon: 'info'
                })
            })
        }
    },
    watch: {
        'latestQuery.status_name': function(nextValue) {
            if (nextValue === 'COMPLETED' && this.$data.tabView === this.$data.tabOptions.QUERY_HISTORY) {
                if (this.latestQuery.mode_name === 'PREVIEW') {
                    this.$axios
                        .get(`/queries/${this.latestQuery.qid}/results`)
                        .then(response => {
                            if (response.data.length) {
                                this.$store.dispatch('snapshotStore/setQueryPreviewData', {
                                    data: response.data,
                                    sql: this.latestQuery.command,
                                    qid: this.latestQuery.qid
                                })
                            } else {
                                this.$store.dispatch('snapshotStore/setQueryPreviewData', {
                                    data: [],
                                    sql: this.latestQuery.command,
                                    qid: this.latestQuery.qid
                                })
                            }
                        })
                        .finally(() => {
                            this.$data.disableQueryPreview = false
                            this.$store.dispatch('showSnackBar', {
                                snackBarText: 'Results are ready!',
                                snackBarTimeout: 5000,
                                snackBarIcon: 'check_circle'
                            })
                            this.$data.tabView = this.$data.tabOptions.QUERY_PREVIEW
                        })
                } else if (this.latestQuery.mode_name === 'FULL_WITH_SAVE') {
                    this.$store.dispatch('snapshotStore/fetchFullQueryData', this.latestQuery.qid).finally(() => {
                        if (this.$store.state.snapshotStore.fullQueryData) {
                            this.$data.disableQueryFiles = false
                            this.$data.tabView = this.$data.tabOptions.QUERY_FILES
                        }
                    })
                }
            }
        },
        tablesStatus: function(nextVal, preVal) {
            if (preVal && preVal !== nextVal && nextVal !== this.$data.tableStatusOptions.ACTIVATING_TABLES) {
                this.$data.activatingTables = false
            }
        },
        tableNamesArray: function(nextVal) {
            if (nextVal.includes(this.$data.currentTableSelected) === false) {
                this.$store.dispatch('snapshotStore/emptyTableData', false)
            }
        }
    },
    deactivated() {
        this.$data.currentTableSelected = ''
        this.$data.tableSearch = ''
        this.$data.tabView = this.$data.tabOptions.TABLE_LIST
        this.$data.disableQueryPreview = true
        this.$data.disableQueryFiles = true
        this.$store.dispatch('snapshotStore/emptyTableData', true)
        clearInterval(this.$data.queryUpdater)
    },
    activated() {
        this.$store.dispatch('userStore/fetchSnowflakeTokens')
        this.$store.dispatch('snapshotStore/setTablesPath', this.$route.params.snid)
        this.$store.dispatch('snapshotStore/fetchQueries', this.$route.params.snid)
        this.$data.queryUpdater = setInterval(() => {
            this.$store.dispatch('snapshotStore/fetchQueries', this.$route.params.snid)
        }, 5000)
    }
}
</script>
