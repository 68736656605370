var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.orgTablesStatus === true
        ? _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c("v-card-title", [
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-column",
                    staticStyle: { width: "100%" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex justify-space-between align-center"
                      },
                      [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "primary--text ma-0 font-weight-bold"
                                },
                                [_vm._v("Tables")]
                              ),
                              _vm.tablesStatus ===
                              _vm.tableStatusOptions.ACTIVATED_TABLES
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        loading:
                                                          _vm.tablesFetching
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.$store.dispatch(
                                                            "snapshotStore/fetchCurrentTables",
                                                            _vm.$route.params
                                                              .snid
                                                          )
                                                        }
                                                      }
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "primary"
                                                        }
                                                      },
                                                      [_vm._v("refresh")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        1226576820
                                      )
                                    },
                                    [_c("span", [_vm._v("Refresh Tables")])]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.tablesFetching === true &&
                          _vm.tablesStatus ===
                            _vm.tableStatusOptions.ACTIVATED_TABLES
                            ? _c(
                                "span",
                                { staticClass: "caption text-uppercase ma-0" },
                                [_vm._v(" Fetching tables.... ")]
                              )
                            : _vm.tablesStatus ===
                              _vm.tableStatusOptions.ACTIVATED_TABLES
                            ? _c(
                                "span",
                                { staticClass: "caption text-uppercase ma-0" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.currentTables.length) +
                                      " tables found "
                                  )
                                ]
                              )
                            : _vm.tablesStatus === null
                            ? _c(
                                "span",
                                { staticClass: "caption text-uppercase ma-0" },
                                [_vm._v("Checking tables status...")]
                              )
                            : _vm._e()
                        ]),
                        _vm.tablesStatus ===
                          _vm.tableStatusOptions.ACTIVATED_TABLES &&
                        _vm.tablesFetching !== true
                          ? _c("div", { staticClass: "d-flex align-center" }, [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _vm.currentTables.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-center mr-2"
                                        },
                                        [
                                          _c("v-switch", {
                                            staticClass: "ma-0 pa-0",
                                            attrs: { "hide-details": "" },
                                            model: {
                                              value: _vm.showSqlEditor,
                                              callback: function($$v) {
                                                _vm.showSqlEditor = $$v
                                              },
                                              expression: "showSqlEditor"
                                            }
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-uppercase subtitle-2 font-weight-bold primary--text"
                                            },
                                            [_vm._v("sql editor")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        "nudge-width": 100,
                                        "max-width": 650,
                                        "offset-y": "",
                                        left: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var menu = ref.on
                                              return [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var tooltip = ref.on
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      text: "",
                                                                      color:
                                                                        "primary",
                                                                      dark: ""
                                                                    }
                                                                  },
                                                                  Object.assign(
                                                                    {},
                                                                    tooltip,
                                                                    menu
                                                                  )
                                                                ),
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex align-center"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            small:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-book-open-variant"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "subtitle-2 font-weight-bold ml-1"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "connection guide"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Connection guide")
                                                    ])
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        116064132
                                      ),
                                      model: {
                                        value: _vm.connectionMenu,
                                        callback: function($$v) {
                                          _vm.connectionMenu = $$v
                                        },
                                        expression: "connectionMenu"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticStyle: {
                                            "overflow-y": "hidden"
                                          }
                                        },
                                        [
                                          _c("v-card-title", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "primary--text font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  "Connection guide for external applications"
                                                )
                                              ]
                                            )
                                          ]),
                                          _vm.currentSpaceType !==
                                          _vm.spaceTypes.VENDOR_SPACE
                                            ? _c("v-card-text", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "subtitle-2 primary--text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " If you want to connect to the database with a 3rd party application, please consult Snowflake's documentation "
                                                        ),
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href:
                                                                "https://docs.nuvolos.cloud/data/access-data-from-applications#accessing-data-tables-from-external-non-nuvolos-applications"
                                                            }
                                                          },
                                                          [_vm._v("here")]
                                                        ),
                                                        _vm._v(". ")
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "subtitle-2 primary--text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "In any case, you'll need the following credentials to connect:"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._l(
                                                      _vm.connectionGuideData,
                                                      function(item, index) {
                                                        return _c(
                                                          "v-row",
                                                          {
                                                            key: index,
                                                            attrs: {
                                                              align: "center",
                                                              "no-gutters": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold text-uppercase secondary--text",
                                                                attrs: {
                                                                  cols: "3"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.item
                                                                  )
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                staticClass:
                                                                  "subtitle-2",
                                                                attrs: {
                                                                  cols: "9"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex align-center"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item.data
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                    _c(
                                                                      "CopyToClipboard",
                                                                      {
                                                                        attrs: {
                                                                          textToCopy:
                                                                            item.data,
                                                                          buttonClass:
                                                                            "caption font-weight-bold",
                                                                          buttonColor:
                                                                            "secondary",
                                                                          isTextButton: true,
                                                                          buttonName:
                                                                            "copy to clipboard",
                                                                          iconButton: true
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "subtitle-2 primary--text"
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Once connected, execute the following SQL command to activate the schema corresponding to the currently selected state: "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-space-between align-center wrap-flex",
                                                        staticStyle: {
                                                          "background-color":
                                                            "rgba(23, 168, 193, 0.1)",
                                                          width: "100%"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "mb-0 mt-0 ml-2 font-weight-bold caption",
                                                            staticStyle: {
                                                              color:
                                                                "rgba(0, 0, 0, 0.54)"
                                                            }
                                                          },
                                                          [_vm._v("SQL Editor")]
                                                        ),
                                                        _c("CopyToClipboard", {
                                                          attrs: {
                                                            textToCopy:
                                                              _vm.connectionQuery,
                                                            buttonClass:
                                                              "caption font-weight-bold",
                                                            buttonColor:
                                                              "secondary",
                                                            isTextButton: true,
                                                            buttonName:
                                                              "copy SQL command to clipboard"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c("MonacoEditor", {
                                                      staticClass:
                                                        "monaco-editor mb-3",
                                                      style: { height: "32px" },
                                                      attrs: {
                                                        language: "sql",
                                                        options: {
                                                          minimap: {
                                                            enabled: false
                                                          },
                                                          fontSize: 14,
                                                          renderLineHighlight: false,
                                                          readOnly: true,
                                                          automaticLayout: true,
                                                          autoIndent: true,
                                                          lineNumbersMinChars: 0,
                                                          scrollbar: {
                                                            vertical: "hidden"
                                                          },
                                                          scrollBeyondLastLine: false,
                                                          smoothScrolling: true
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.connectionQuery,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.connectionQuery = $$v
                                                        },
                                                        expression:
                                                          "connectionQuery"
                                                      }
                                                    })
                                                  ],
                                                  2
                                                )
                                              ])
                                            : _vm.currentSpaceType ===
                                              _vm.spaceTypes.VENDOR_SPACE
                                            ? _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "v-alert",
                                                    {
                                                      attrs: {
                                                        type: "warning",
                                                        prominent: "",
                                                        text: ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " You cannot access vendor datasets directly from 3rd party applications. To access data with an external application, stage the tables you want to work with and distribute them to a course/research space. "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex justify-end" },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            text: "",
                                                            color: "primary",
                                                            href:
                                                              "https://docs.nuvolos.cloud/data/access-data-from-applications/upload-data-to-nuvolos",
                                                            target: "_blank"
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex align-center"
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "menu_book"
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "subtitle-2 font-weight-bold ml-1"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "import data"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            1202443820
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Read the documentation on how to import and upload data to Nuvolos"
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ]
                )
              ]),
              _c(
                "v-card-text",
                { staticClass: "mt-3" },
                [
                  _vm.tablesStatus ===
                    _vm.tableStatusOptions.ACTIVATED_TABLES &&
                  _vm.tablesFetching !== true &&
                  _vm.currentTables.length
                    ? _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-card-text",
                            { staticClass: "pa-0" },
                            [
                              _vm.showSqlEditor
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex align-center",
                                          staticStyle: {
                                            "background-color":
                                              "rgba(23, 168, 193, 0.1)",
                                            width: "100%"
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "my-0 ml-2 font-weight-bold caption",
                                              staticStyle: {
                                                color: "rgba(0, 0, 0, 0.54)"
                                              }
                                            },
                                            [_vm._v("SQL Editor")]
                                          )
                                        ]
                                      ),
                                      _c("MonacoEditor", {
                                        staticClass: "monaco-editor mb-3",
                                        style: { height: _vm.queryLines },
                                        attrs: {
                                          language: "sql",
                                          options: {
                                            minimap: { enabled: false },
                                            fontSize: 14,
                                            renderLineHighlight: false,
                                            automaticLayout: true,
                                            autoIndent: true,
                                            lineNumbersMinChars: 3,
                                            scrollbar: { vertical: "hidden" },
                                            scrollBeyondLastLine: false,
                                            smoothScrolling: true
                                          }
                                        },
                                        model: {
                                          value: _vm.sqlQuery,
                                          callback: function($$v) {
                                            _vm.sqlQuery = $$v
                                          },
                                          expression: "sqlQuery"
                                        }
                                      }),
                                      _c("QuerySubmit", {
                                        attrs: {
                                          mode: "PREVIEW",
                                          buttonName: "preview",
                                          tooltipHint:
                                            "Returns a preview sample of the query outcome.",
                                          iconShaped: false,
                                          consoleSql: _vm.sqlQuery
                                        },
                                        on: {
                                          success: function($event) {
                                            _vm.tabView =
                                              _vm.tabOptions.QUERY_HISTORY
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-tabs",
                                {
                                  staticClass: "mt-5 tabs-grey-border-bottom",
                                  attrs: {
                                    height: "30",
                                    color: "secondary",
                                    "show-arrows": ""
                                  },
                                  model: {
                                    value: _vm.tabView,
                                    callback: function($$v) {
                                      _vm.tabView = $$v
                                    },
                                    expression: "tabView"
                                  }
                                },
                                [
                                  _c("v-tabs-slider", {
                                    attrs: { color: "teal lighten-3" }
                                  }),
                                  _c(
                                    "v-tab",
                                    { attrs: { dark: "", height: "10" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            "tables (" +
                                              _vm._s(_vm.currentTables.length) +
                                              ")"
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-tab-item",
                                    { staticClass: "mt-5" },
                                    [
                                      _c("SnapshotTableList", {
                                        on: {
                                          switchTab: function($event) {
                                            _vm.tabView = $event.tab
                                          },
                                          newTableSelected: function($event) {
                                            _vm.currentTableSelected =
                                              $event.table
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      attrs: {
                                        dark: "",
                                        height: "10",
                                        disabled: !_vm.currentColumns.length
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [
                                          _vm._v(
                                            "columns (" +
                                              _vm._s(
                                                _vm.currentColumns.length
                                              ) +
                                              ")"
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-tab-item",
                                    { staticClass: "mt-3" },
                                    [
                                      _c("SnapshotTableColumns", {
                                        attrs: {
                                          currentTableSelected:
                                            _vm.currentTableSelected
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      attrs: {
                                        dark: "",
                                        height: "10",
                                        disabled: !_vm.currentTableSelected
                                          .length
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [_vm._v("table preview")]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-tab-item",
                                    { staticClass: "mt-3" },
                                    [
                                      _c("SnapshotTablePreview", {
                                        attrs: {
                                          currentTableSelected:
                                            _vm.currentTableSelected
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      attrs: {
                                        dark: "",
                                        height: "10",
                                        disabled: _vm.disableQueryFiles
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [_vm._v("Query Files")]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-tab-item",
                                    { staticClass: "mt-3" },
                                    [_c("QueryFiles")],
                                    1
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      attrs: {
                                        dark: "",
                                        height: "10",
                                        disabled: _vm.disableQueryPreview
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [_vm._v("query preview")]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-tab-item",
                                    { staticClass: "mt-5" },
                                    [
                                      _c("QueryTablePreview", {
                                        on: {
                                          querySubmitSuccess: function($event) {
                                            _vm.tabView =
                                              _vm.tabOptions.QUERY_HISTORY
                                          },
                                          queryFilesSaveSuccess: function(
                                            $event
                                          ) {
                                            _vm.tabView =
                                              _vm.tabOptions.QUERY_HISTORY
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tab",
                                    { attrs: { dark: "", width: "50" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "font-weight-bold" },
                                        [_vm._v("query history")]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-tab-item",
                                    { staticClass: "mt-5" },
                                    [
                                      _vm.snapshotQueries.length
                                        ? _c("QueryHistory", {
                                            attrs: {
                                              snapshotQueries:
                                                _vm.snapshotQueries
                                            },
                                            on: {
                                              queryResultsSuccess: function(
                                                $event
                                              ) {
                                                return _vm.moveToQueryPreview()
                                              },
                                              sqlText: function($event) {
                                                return _vm.pasteIntoConsole(
                                                  $event.value
                                                )
                                              },
                                              queryFilesSuccess: function(
                                                $event
                                              ) {
                                                return _vm.moveToQueryFiles()
                                              }
                                            }
                                          })
                                        : _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                prominent: "",
                                                type: "info",
                                                text: ""
                                              }
                                            },
                                            [_vm._v("Query history is empty.")]
                                          )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm.tablesFetching === true
                    ? _c("div", { staticClass: "mt-5" }, [
                        _c(
                          "div",
                          [
                            _c("v-skeleton-loader", {
                              staticClass: "mx-auto",
                              attrs: { type: "text" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          _vm._l(3, function(n, i) {
                            return _c("v-skeleton-loader", {
                              key: i,
                              staticClass: "mx-auto",
                              attrs: { type: "list-item-three-line" }
                            })
                          }),
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.tablesFetching === false &&
                  (_vm.tablesStatus ===
                    _vm.tableStatusOptions.ACTIVATING_TABLES ||
                    _vm.activatingTables)
                    ? _c(
                        "v-banner",
                        {
                          attrs: { "single-line": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "actions",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          loading: _vm.fetchingSpaces,
                                          small: "",
                                          color: "info",
                                          outlined: ""
                                        },
                                        on: { click: _vm.fetchTableStatus }
                                      },
                                      [_vm._v("refresh")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            777470645
                          )
                        },
                        [
                          _c(
                            "v-avatar",
                            {
                              attrs: {
                                slot: "icon",
                                color: "white",
                                size: "60"
                              },
                              slot: "icon"
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    "x-large": "",
                                    icon: "info",
                                    color: "info"
                                  }
                                },
                                [_vm._v(" info ")]
                              )
                            ],
                            1
                          ),
                          _vm._v(
                            " Tables are being activated, this can take a few minutes. You can periodically use the refresh button to see if activation has finished. "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.tablesStatus === _vm.tableStatusOptions.INACTIVE_TABLES &&
                  _vm.activatingTables === false
                    ? _c(
                        "v-banner",
                        {
                          attrs: { "single-line": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "actions",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          small: "",
                                          color: "info",
                                          outlined: ""
                                        },
                                        on: { click: _vm.activateTable }
                                      },
                                      [_vm._v("activate tables")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            486029526
                          )
                        },
                        [
                          _c(
                            "v-avatar",
                            {
                              attrs: {
                                slot: "icon",
                                color: "white",
                                size: "60"
                              },
                              slot: "icon"
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    "x-large": "",
                                    icon: "info",
                                    color: "info"
                                  }
                                },
                                [_vm._v(" info ")]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Tables not activated")
                            ]),
                            _vm._v(
                              " You haven't activated the tables features for this state yet. "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  (_vm.activatingTables === false &&
                    _vm.tablesStatus === _vm.tableStatusOptions.EMPTY_TABLES) ||
                  (_vm.activatingTables === false &&
                    _vm.tablesStatus ===
                      _vm.tableStatusOptions.ACTIVATED_TABLES &&
                    _vm.tablesFetching === false &&
                    !_vm.currentTables.length)
                    ? _c(
                        "v-banner",
                        { attrs: { "single-line": "" } },
                        [
                          _c(
                            "v-avatar",
                            {
                              attrs: {
                                slot: "icon",
                                color: "white",
                                size: "60"
                              },
                              slot: "icon"
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    "x-large": "",
                                    icon: "info",
                                    color: "info"
                                  }
                                },
                                [_vm._v(" info ")]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("No tables found")
                            ]),
                            _vm.isDevelopment
                              ? _c("span", [
                                  _vm._v(
                                    " No tables were found. This might be because there are no tables in this space or tables have not been activated. "
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    "No tables were found in this snapshot. "
                                  )
                                ])
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm.orgTablesStatus === false
        ? _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c("v-card-title", [
                _c("span", { staticClass: "primary--text font-weight-bold" }, [
                  _vm._v("Tables")
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-alert",
                    { attrs: { type: "info", prominent: "", text: "" } },
                    [
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Tables not activated")
                        ]),
                        _vm._v(
                          " The current subscription does not allow for the usage of Tables in Nuvolos. Please reach out to your organization administrator if you require this feature. "
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }